<template>
	<div class="modal" style="display:block">
		<!-- <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}"> -->
        <div class="modal-content">

            <template v-if="accesRefuseIndicesManquants == true">
                <div class="ordinateur">
                    <p class="message">Il semblerait que vous n’ayez pas collecté tous les indices pour pouvoir accéder à l'ordinateur.</p>
                </div>
            </template>


            <template v-if="accesRefuseIndicesManquants == false">
                <template v-if="step == 1">
                    <div class="ordinateur">
                        <p>Entrez votre mot de passe</p>
                        <input ref="password" v-model="form.password" type="text" maxlength="10">

                        <p v-if="hasError == true">Mot de passe erroné, veuillez réessayer</p>

                        <button v-if="hasError !== false" type="button" class="btn btn-blue align-center" @click="checkPassword()"><span>Valider</span></button>
                    </div>
                </template>

                <template v-if="step == 2">
                    <div class="ordinateur-applis">
                        <span class="number">1</span>
                        <span class="link" @click="goNext()"></span>
                    </div>
                </template>

                <template v-if="step == 3">
                    <div class="ordi-emails step1">
                        <button class="btn btn-blue btn-rediger" disabled>Rédiger un message</button>
                        <button class="btn btn-blue btn-ouvrir" @click="goNext()">Ouvrir</button>
                    </div>
                </template>

                <template v-if="step == 4">
                    <div class="ordi-emails step2">
                        <button class="btn btn-blue btn-rediger" disabled>Répondre</button>
                    </div>
                </template>

                <template v-if="step == 5">
                    <div class="ordi-emails step3">
                        <button class="btn btn-blue btn-rediger" @click="lanceQuizDermato()">Rédiger un message</button>
                    </div>
                </template>
            </template>

        </div>

        <div class="modal-footer">
            <!-- tant que pas connecté à l'ordi, on laisse le choix de revenir dans le 360 du bureau -->
            <button v-if="hasError !== false" type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>

            <button v-if="step == 4" type="button" class="btn btn-blue align-right" @click="goNext()"><span>Suivant</span></button>
        </div>
    </div>
</template>


<script>
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'OrdiMedecin',
    data() {
        return {
            step : 1,
            form : {
                password: null,
            },
            hasError: null,
            accesRefuseIndicesManquants: null
        }
    },
    mounted() {
        if (this.nbMedical == 3 && this.nbRessources == 4 && this.nbIndices == 2) {
        // if (this.nbIndices == 1) { // pour tests plus rapides
            this.accesRefuseIndicesManquants = false
        } else {
            this.accesRefuseIndicesManquants = true
        }
    },
    computed : {
		nbMedical () {
			return this.$store.state.dossiers.medical.length
		},
		nbIndices () {
			return this.$store.state.dossiers.indices.length
		},
		nbRessources () {
			return this.$store.state.dossiers.ressources.length
		},
    },
	methods : {
		close() {
			this.$emit('close')
		},
        goNext(){
            this.step++;
        },
        checkPassword() {
            if (this.form.password == "toto-1234") {
                this.hasError = false
                this.goNext()
            } else {
                this.hasError = true
            }
        },
        lanceQuizDermato() {
            EventBus.$emit('changeQuiz', 'qcm-bg-messagerie', 4);
        }
        // ecranSuivant() {
        //     // on enchaine sur le QCM imc
        //     let nextQuiz = this.quizList[this.current.enchaineQuizIndex];
        //     EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);
        // }
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .modal-content {
        padding: 0;
    }

    .ordinateur {
        background: url('/img/ordinateur.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            color: white;
            font-weight: 500;
        }

        input {
            border: none;
            font-size: 4.2rem;
            height: 5.5rem;
            margin-bottom: 3rem;
            text-align: center;
            width: 25rem;
        }

        .btn {
            position: relative;
            left: auto;
            transform: none;
        }

        .message {
            background: white;
            border-radius: 2rem;
            color: red;
            padding: 2rem 4rem;
            width: 60%;
        }
    }

    .ordinateur-applis {
        background: url('/img/ordi-open-appli.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .link {
            cursor: pointer;
            position: absolute;
            height: 17rem;
            width: 14rem;
            top: 11.5rem;
            left: 29.5rem;
        }

        .number {
            background: red;
            color: white;
            border-radius: 50%;
            line-height: 3rem;
            height: 3rem;
            width: 3rem;
            position: absolute;
            top: 24.5rem;
            left: 41rem;
        }
    }

    .ordi-emails {
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        button {
            &:hover {
                background: white;
                color: $turquoise;
            }
        }

        .btn {
            border-radius: 1.5rem;
            font-size: 1.7rem;
            height: 4.2rem;
            padding: 0 2rem;
            position: absolute;

            &:before {
                display: none;
            }

            &-rediger {
                top: 7rem;
                right: 6rem;
            }

            &:disabled {
                color: white !important;
                background: grey;
                border: none !important;
                cursor: default;
            }
        }

        &.step1 {
            background-image: url('/img/ordi-messagerie1.png');

            .btn {
                &-ouvrir {
                    top: 20rem;
                    right: 6rem;
                }
            }
        }

        &.step2 {
            background-image: url('/img/ordi-messagerie2.png');
        }

        &.step3 {
            background-image: url('/img/ordi-messagerie3.png');
        }
    }
</style>